<template>
	<div class="">
		<div class="rzBox"> </div>
		<div
			class="Main width_1200 margin_auto flex justify_content_sb"
			style="padding-bottom: 20px"
		>
			<div
				class="MainRight_2 MainRight_3 margin_top_20 steps kxsteps rzkxsteps"
			>
				<div class="MainRight_3_title">合作相关</div>
				<steps :dataList="CooperationDetails"></steps>
			</div>
		</div>
	</div>
</template>

<script>
import { getCooperationDetails } from "@/api/home";
export default {
	name: "",
	data() {
		return {
			CooperationDetails: [],
		};
	},
	created() {
		this.getCooperationDetails();
	},
	methods: {
		getCooperationDetails() {
			getCooperationDetails({})
				.then((res) => {
					if (res.code == 200) {
						this.CooperationDetails = res.data.list;
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
	},
};
</script>
<style>
.el-step__icon-inner {
	display: none;
}
.steps .el-step__icon {
	width: 9px;
	height: 9px;
}
.steps .el-step__icon.is-text {
	/* background: #6974c8; */
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	/* border: 4px solid; */
}
.steps .el-step.is-vertical .el-step__line {
	left: 4px;
}
.el-step__head.is-wait {
	color: #000000;
	border-color: #dedee1;
}
.el-step__head.is-finish {
	color: #000000;
	border-color: #dedee1;
}
.el-step__description {
	margin-top: 0px;
}
.contentStep {
	font-size: 14px;
	font-weight: 500;
	color: #767680;
	margin-top: 12px;
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 5;
	-webkit-box-orient: vertical;
}
* {
	font-size: 14px;
	font-weight: 500;
	color: #767680;
}
</style>
<style scoped>
.rzBox {
	width: 100%;
	height: 290px;
	background-image: url("https://res.metaera.hk/resources/assets/images/rongzi/1064.png");
	background-size: 100% 100%;
}

.kxstepsBox {
	margin-top: 15px;
}

.kxstepsBoxLeft {
	width: 50%;
}

.kxstepsBox a {
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #0056ff;
}

.MainRight_3_title {
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
	margin: 30px 0;
}
</style>
<style>
.kxsteps .stepsVal {
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
	margin-top: 15px;
}

.kxsteps .el-step__main {
	height: 118px;
}

.kxsteps .el-step__description {
	padding-right: 0% !important;
}

.kxsteps .btnitem div {
	color: #a0a0a8;
}

.chanyeTitle .rsTitle {
	margin-top: 0 !important;
}
</style>
